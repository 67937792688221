import Container from '../components/Container';
import { useState } from 'react';
import CustomCursor from '../components/CustomCursor';
import ButtonMenu from '../components/ButtonMenu';
import FooterButton from '../components/Footer/footerButton';
import HeaderLogo from '../components/Header/logo';

export default function DesktopWrapper({currentPath}) {
  const [scrollXProgress, setScrollXProgress] = useState(0);
  const [contactFormPosition, setContactFormPosition] = useState(-100);

  const onScroll = (latest) => {
    setScrollXProgress(latest);
  };

  const darkTheme = ['/cloud'].includes(currentPath);
  const darkMenu = ['/impressum', '/datenschutz'].includes(currentPath);

  return (
    <div className="desktop-wrapper">
      <CustomCursor darkTheme={contactFormPosition > 0.01 ? true : darkTheme}/>
      <header>
        <HeaderLogo darkTheme={contactFormPosition > 0.7 ? true : false}/>
        <ButtonMenu currentPath={currentPath} darkTheme={(contactFormPosition > 0.01 || darkMenu) ? true : darkTheme}/>
      </header>
      <Container onScroll={onScroll} page={currentPath} setContactFormPosition={setContactFormPosition}/>
      {currentPath === '/' &&
        <FooterButton scrollXProgress={scrollXProgress} contactFormPosition={contactFormPosition}/>}
    </div>
  );
}