import Headline from '../Headline';
import IconArrow from '../Icons/arrow';

export default function MainPageSection1(props) {

  return (
    <section {...props}>
      <div style={{position: 'relative'}}>
        <div className="hstack gap-3" style={{position: 'absolute', right: -50, bottom: 50, width: 300}}>
          <div>Scrollen und entdecken Sie mehr</div>
          <IconArrow/>
        </div>
        <Headline/>
      </div>

    </section>
  );

}