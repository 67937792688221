import MainPageSection1 from './section1';
import MainPageSection2 from './section2';
import MainPageSection3 from './section3';
import MainPageSection4 from './section4';
import MainPageSection5 from './section5';
import MainPageSection6 from './section6';
import MainPageSection7 from './section7';
import ContactForm from '../ContactForm';
import Hero from '../Heros';
import { motion } from 'framer-motion';
import srcPhoneBlue from '../../assets/img/handy-mock-up.png';


export default function PageContentHome({x}) {
  return (
    <div style={{display: 'flex', alignItems: 'center', position: 'relative'}}>
      <div className="bg-pill-wrapper">
        <div
          style={{position: 'absolute', zIndex: 100, right: 0, width: 400, top: -3000, bottom: -3000}}
          className="bg-c1"
        ></div>
        <div className="bg-pill lg-y-g" style={{zIndex: 110, position: 'relative'}}></div>
      </div>
      <MainPageSection1 style={{marginLeft: 0}}/>
      <motion.div animate={{x: 2200 * x - 100}} style={{zIndex: 400}}>
        <Hero variant={'CV1'}/>
      </motion.div>
      <MainPageSection2 style={{width: '73vw', paddingLeft: 200, marginRight: '-4vw', zIndex: 500}}/>
      <MainPageSection3 style={{width: '60vw'}}/>
      <MainPageSection4 style={{width: 890, marginRight: -300}}/>
      <motion.div animate={{x: 1000 * x - 300}} style={{zIndex: 400}} initial={false} transition={{duration: 0.1}}>
        <Hero variant={'SJ1'}/>
      </motion.div>
      <MainPageSection5 style={{width: 800, paddingLeft: 200}}/>
      <MainPageSection6 style={{width: '65vw'}}/>
      <div style={{zIndex: 500, height: '48vh', display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
        <img src={srcPhoneBlue} style={{maxHeight: '60vh'}} alt={''}/>
      </div>
      <MainPageSection7 style={{width: 850, paddingRight: 150}}/>
      <ContactForm/>
    </div>
  );
}