export default function MainPageSection5(props) {

  return (
    <section {...props}>
      <div style={{fontSize: '1.5em', fontWeight: 500}}>
        Inspektionen folgen den Richtlinien der DIN EN 1176 und werden digital durchgeführt, um eine gründliche und
        transparente Dokumentation zu gewährleisten.
      </div>
    </section>

  );

}