import MainPageSection1 from './section1';
import MainPageSection2 from './section2';
import ContactForm from '../ContactForm';
import BlackPill from '../BlackPill';


export default function PageContentCloud({x}) {
  return (
    <>
      <MainPageSection1 style={{paddingLeft: 100, width: '95vw'}}/>
      <MainPageSection2 style={{width: '90vw', fontSize: '2.7rem', marginLeft: '-8vw'}}/>
      <BlackPill page={'cloud'} x={x}/>
      <ContactForm/>
    </>
  );
}