import IconArrow from '../Icons/arrow';

export default function MainPageSection7(props) {

  return (
    <section {...props}>
      <div style={{fontSize: 64, marginBottom: 25}}>
        Starten Sie Jetzt!
      </div>
      <div style={{fontSize: 24}} className="hstack">
        <div style={{width: 350}}>
          Teilen Sie uns in drei Schritten mit, wie wir Ihre individuellen Anforderungen erfüllen können.
        </div>
        <div className="text-b1 ms-auto">
          <IconArrow/>
        </div>
      </div>
    </section>

  );

}