import { textBlock } from '../../helper/constants';

export default function MainPageSection2(props) {

  return (
    <section {...props}>
      <div style={{marginBottom: '1em', fontSize: '1.1em'}}>
        "{textBlock.wartung[1]}"
      </div>
      <div className="fw-light">
        {textBlock.wartung[2]}
      </div>
    </section>
  );
}