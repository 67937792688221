import React from 'react';
import img from '../../assets/img/christian-veit-02.png';
import Button from '../Button';
import { isMobile } from '../../helper/constants';

export default function ContactFormPageFinish({setPage, formData, scrollBack}) {


  for (const li of document.querySelectorAll('header')) {
    li.setAttribute('class', 'd-none');
  }

  return (
    <div style={{position: 'relative'}}>
      {!isMobile &&
        <div style={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          height: 0,
          top: '50%',
          left: '-25vw',
          zIndex: 0,
          width: '100%',
          paddingTop: 100,
        }}>
          <img src={img} style={{height: 'calc(100vh - 100px)'}} alt={'Wadiki Cloud - Christian Veit'}/>
        </div>
      }
      <div style={{
        zIndex: 1,
        position: 'relative',
        paddingRight: isMobile ? 0 : 100,
        transform: isMobile ? 'none ' : 'translate(160px,0)',
      }}>

        <div style={{fontSize: 24, marginBottom: 20}}>Yeah, was für ein toller Tag</div>
        <h2>Gute Neuigkeiten!</h2>
        <div className="mt-5" style={{marginBottom: 60, fontSize: 24}}>
          Wir haben Ihre Anfrage erhalten und unser Wadiki Team bearbeitet sie nun.<br/><br/>
          Es kann einige Sekunden dauern, bis Sie die E-Mail-Bestätigung von uns erhalten.
        </div>
        <div className="d-flex flex-column flex-lg-row gap-3 justify-content-between">
          <a href={'#cf-finish-one-more'}>

            <Button className="btn-outline text-c1 block" onClick={() => {
              setPage(1);
            }}>
              weitere Anfrage?
            </Button>
          </a>
          <a
            href={'#cf-finish-to-start'}
            onClick={() => {
              setPage(1);
              scrollBack && scrollBack();
            }}>
            <Button className="btn-outline text-c1 block">zum Start</Button>
          </a>
        </div>
      </div>

    </div>
  );

}