import { useEffect, useState } from 'react';
import DesktopWrapper from './desktop';
import MobileWrapper from './mobile';
import { breakPoint, isMobile } from '../helper/constants';
import { paths } from '../index';

export default function Root({currentPath}) {
  const [mobile, setMobile] = useState(isMobile);

  const thisPath = paths.find(i => i.path === currentPath);
  document.title = 'WADIKI - ' + thisPath.label;

  useEffect(() => {

    window.removeEventListener('resize', onResize);
    window.addEventListener('resize', onResize);
    document.body.setAttribute('class', 'page-' + currentPath.substring(1));
  }, [currentPath]);


  const onResize = () => {
    // const isPortrait = window.innerHeight > window.innerWidth;
    // const pixelRatio = window.devicePixelRatio;
    //const isMobile = isPortrait ? window.screen.width < breakPoint : window.screen.height < breakPoint;
    const isMobile = window.screen.width < breakPoint;
    setMobile(isMobile);
  };

  return <>
    {mobile ?
      <MobileWrapper currentPath={currentPath}/>
      :
      <DesktopWrapper currentPath={currentPath}/>
    }
  </>;
}