export default function IconClose() {

  const variants = [
    <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="59" y="2" width="57" height="57" rx="28.5" transform="rotate(90 59 2)" stroke="currentColor"
            strokeWidth="4"/>
      <path
        d="M31.82 30.58L35.84 35C36.2933 35.5067 36.52 35.9333 36.52 36.28C36.52 36.6267 36.26 37.0333 35.74 37.5C35.2333 37.9667 34.8 38.2 34.44 38.2C34.0933 38.2 33.6867 37.9333 33.22 37.4L29.42 33.04L25.62 37.4C25.14 37.9333 24.7267 38.2 24.38 38.2C24.0467 38.2 23.6133 37.9667 23.08 37.5C22.56 37.02 22.3 36.6133 22.3 36.28C22.3 35.9467 22.5333 35.52 23 35L27.02 30.58L23 26.16C22.5333 25.6667 22.3 25.2467 22.3 24.9C22.3 24.5533 22.56 24.1467 23.08 23.68C23.6 23.2 24.0333 22.96 24.38 22.96C24.74 22.96 25.1533 23.2267 25.62 23.76L29.42 28.12L33.22 23.76C33.7 23.2267 34.1067 22.96 34.44 22.96C34.7867 22.96 35.22 23.1933 35.74 23.66C36.26 24.1267 36.52 24.54 36.52 24.9C36.52 25.26 36.2933 25.68 35.84 26.16L31.82 30.58Z"
        fill="currentColor"/>
    </svg>,
    <svg width="46" height="50" viewBox="0 0 46 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30.7052 25.2561L43.5692 39.4001C45.0199 41.0214 45.7452 42.3867 45.7452 43.4961C45.7452 44.6054 44.9132 45.9067 43.2492 47.4001C41.6279 48.8934 40.2412 49.6401 39.0892 49.6401C37.9799 49.6401 36.6785 48.7867 35.1852 47.0801L23.0252 33.1281L10.8652 47.0801C9.32921 48.7867 8.00654 49.6401 6.89721 49.6401C5.83054 49.6401 4.44388 48.8934 2.73721 47.4001C1.07321 45.8641 0.241211 44.5627 0.241211 43.4961C0.241211 42.4294 0.987878 41.0641 2.48121 39.4001L15.3452 25.2561L2.48121 11.1121C0.987878 9.53341 0.241211 8.1894 0.241211 7.08007C0.241211 5.97074 1.07321 4.6694 2.73721 3.17607C4.40121 1.64007 5.78788 0.87207 6.89721 0.87207C8.04921 0.87207 9.37188 1.7254 10.8652 3.43207L23.0252 17.3841L35.1852 3.43207C36.7212 1.7254 38.0225 0.87207 39.0892 0.87207C40.1985 0.87207 41.5852 1.61874 43.2492 3.11207C44.9132 4.6054 45.7452 5.92807 45.7452 7.08007C45.7452 8.23207 45.0199 9.57607 43.5692 11.1121L30.7052 25.2561Z"
        fill="currentColor"/>
    </svg>,

  ];

  return variants[0];
}