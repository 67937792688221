export default function MainPageSection6(props) {

  return (
    <section {...props}>
      <div style={{fontSize: '2em'}}>
        "Mit unserem digitalen System überprüfen wir Spielplätze in Echtzeit und helfen Ihnen kluge Entscheidungen zu
        treffen."
      </div>
    </section>

  );

}