import MainPageSection1 from './section1';
import MainPageSection2 from './section2';
import ContactForm from '../ContactForm';
import BlackPill from '../BlackPill';


export default function PageContentWartung({x}) {
  return (
    <>
      <MainPageSection1 style={{paddingLeft: 100, width: '90vw'}}/>
      <MainPageSection2 style={{width: '90vw', fontSize: '2rem', marginLeft: -100}}/>
      <BlackPill page={'wartung'} x={x}/>
      <ContactForm/>
    </>
  );
}