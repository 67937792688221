import { isMobile } from '../../helper/constants';
import ScrollForMore from '../ScrollForMore';

export default function MainPageSection1(props) {

  return (
    <section {...props}>
      <div style={{fontSize: '4em', marginBottom: isMobile ? 40 : 60}}>
        Sicherheit geht vor, wir prüfen Ihren Spielplatz nach DIN EN 1176.
      </div>
      <ScrollForMore/>
    </section>
  );

}