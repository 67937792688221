import MainPageSection1 from './section1';
import MainPageSection2 from './section2';
import ContactForm from '../ContactForm';
import ContactFormHeader from '../ContactForm/header';
import { textBlock } from '../../helper/constants';
import Hero from '../Heros';


export default function MobilePageContentCloud() {

  return (
    <>
      <div className="p-40" style={{fontSize: '10px'}}>
        <MainPageSection1/>
      </div>
      <div className="p-40 pb-0" style={{fontSize: '2em'}}>
        <h1>WADIKI Cloud</h1>
        <div className="p-2"></div>
        <MainPageSection2/>
        <div className="p-4"></div>
        <div className="fs-1">
          "{textBlock.cloud[0]}"
          <div className="p-4"></div>
          <div>Christian</div>
        </div>
      </div>
      <Hero variant={'CV1'} width={'80vw'} pillColor={'lg-y-g'}/>
      <ContactFormHeader page={'cloud'}/>
      <ContactForm/>
    </>
  );
}