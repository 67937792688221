import IconArrow from '../Icons/arrow';


export default function ScrollForMore() {
  return <div style={{fontSize: 32}}>
    <div className="hstack gap-3">
      <div className="d-none d-lg-block">Scrollen Sie, um mehr zu erfahren.</div>
      <div className="mx-auto mx-lg-0 my-3 my-lg-0">
        <IconArrow/>
      </div>
    </div>
  </div>;
}