import MainPageSection1 from './section1';
import MainPageSection2 from './section2';
import ContactForm from '../ContactForm';
import BlackPill from '../BlackPill';


export default function PageContentInspektion({x}) {
  return (
    <>
      <MainPageSection1 style={{paddingLeft: '10vw', width: '92vw'}}/>
      <MainPageSection2 style={{width: '90vw', fontSize: '2rem', marginLeft: '-5vw'}}/>
      <BlackPill page={'inspektion'} x={x}/>
      <ContactForm/>
    </>
  );
}