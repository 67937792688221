import MainPageSection1 from './section1';
import MainPageSection2 from './section2';
import ContactForm from '../ContactForm';
import Hero from '../Heros';
import ContactFormHeader from '../ContactForm/header';
import { textBlock } from '../../helper/constants';


export default function MobilePageContentWartung() {
  return (
    <>
      <div className="p-40">
        <div style={{fontSize: '10px'}}>
          <MainPageSection1/>
        </div>
      </div>
      <div className="p-40">
        <div style={{fontSize: '2em'}}>
          <h1>WADIKI Wartung</h1>
          <div className="p-2"></div>
          <MainPageSection2/>
          <div className="p-2"></div>
          <div className="mt-5">
            "{textBlock.wartung[0]}"
          </div>
          <div className="p-4"></div>
          <div>
            Christian
          </div>
        </div>
        <div className="p-3"></div>
      </div>
      <Hero variant={'CV2'} width="70vw" pillColor={'lg-y-g'}/>

      <ContactFormHeader page={'wartung'}/>
      <ContactForm/>
    </>
  );
}