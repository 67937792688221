import IconInstagram from '../Icons/instagram';
import IconFacebook from '../Icons/facebook';
import IconArrow from '../Icons/arrow';

export default function Footer({scrollXProgress}) {
  return (
    <section
      className="bg-c1 h-100 text-w1 p-40 footer"
      style={{minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'end', fontSize: 24}}
    >
      <div className="d-flex flex-column align-items-end w-100" style={{gap: 40}}>
        <div className="text-primary" style={{width: 'max-content', marginBottom: 100, height: 260}}>
          <div style={{transform: 'scale(3) rotate(90.1deg) translate(50px,20px)'}}>

            <div className="lg-primary cutoff">
              Besser<br/>sicher mit<br/>Wadiki
            </div>
          </div>

          {/*<IconFooterSlogan/>*/}
        </div>
        <div className="d-flex align-items-end flex-column">
          Folge Uns
        </div>
        <div>
          <div className="hstack gap-3 text-primary">
            <a href={'https://www.instagram.com/wadiki.spielplatz/'} target={'_new'}>
              <IconInstagram/>
            </a>
            <a href={'https://www.facebook.com/profile.php?id=100063475475380'} target={'_new'}>
              <IconFacebook/>
            </a>
          </div>
        </div>
        <div className="fw-light text-end d-flex flex-column gap-2">
          <div>
            <a href={'/impressum'} className="text-decoration-underline">Impressum</a>
          </div>
          <div>
            <a href={'/datenschutz'} className="text-decoration-underline">Datenschutz</a>
          </div>
        </div>
        <div className="fw-bold">&copy; WADIKI 2024</div>
        <div className="d-flex d-lg-none justify-content-center w-100">
          <a href="#Start">
            <div style={{transform: 'rotate(180deg'}}><IconArrow/></div>
          </a>
        </div>
      </div>

    </section>
  );
}