import React from 'react';


export default function ContactFormHeader({page}) {

  const data = {
    home: {title: 'Starten Sie jetzt', bgClass: 'text-y1'},
    wartung: {title: 'Wartung anfragen', bgClass: 'text-y1'},
    inspection: {title: 'Inspektion anfragen', bgClass: 'text-g1'},
    cloud: {title: 'Jetzt berechnen', bgClass: 'text-b2'},
  };

  return (
    <div className={`${data[page].bgClass || ''} p-40 bg-c1`}>
      <div className="p-3"></div>
      <h1>{data[page].title}</h1>
      <div className="p-3"></div>
      <div className="text-w1 fs-2 fw-light ">
        Erhalten Sie in drei einfachen Schritten Ihr kostenloses Angebot.
      </div>
    </div>
  );

}