export default function MainPageSection3(props) {

  return (
    <section {...props}>
      <div style={{fontSize: '1.5em', fontWeight: 500}}>
        Bei Wadiki kümmern wir uns um Spielplätze, reparieren Dinge sofort, um sicherzustellen, dass es immer sicher ist
        und Spaß macht.
      </div>
    </section>
  );

}