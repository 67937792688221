import React, { useState } from 'react';
import ContactFormPage1 from './page1';
import ContactFormPage2 from './page2';
import ContactFormPage3 from './page3';
import ContactFormPageFinish from './page-finish';
import Button from '../Button';
import { isMobile } from '../../helper/constants';
import IconArrow from '../Icons/arrow';
import { goToStart } from '../../helper/navigation';


export default function ContactForm() {
  const [page, setPage] = useState(1);
  const [need, setNeed] = useState(null);
  const [formData, setFormData] = useState(null);

  const scrollBack = () => {
    console.log('goBack');
    setPage(1);
    goToStart();
  };

  const onPageChange = (newPage) => {
    for (const li of document.querySelectorAll('header')) {
      li.setAttribute('class', '');
    }
    const item = document.getElementById('contact-form');
    item.scrollIntoView();
    setPage(newPage);
  };


  const pages = [
    {
      title: 'Was benötigen Sie?',
      item: <ContactFormPage1 setNeed={setNeed} setPage={onPageChange}/>,
    },
    {
      title: 'Wo benötigen Sie die Maßnahme?',
      item: <ContactFormPage2 setFormData={setFormData} formData={formData} need={need} setPage={onPageChange}/>,
    },
    {
      title: 'Ist alles richtig?',
      item: <ContactFormPage3 formData={formData} setPage={onPageChange}/>,
    },
    {
      title: '',
      item: <ContactFormPageFinish setPage={onPageChange} scrollBack={scrollBack}/>,
    },
  ];


  return (
    <section
      id="contact-form"
      className={`${page < 4 ? 'bg-c1 text-w1' : 'lg-y-g text-c1 splash'} ${isMobile ? '' : 'fullscreen'} p-4 `}
      style={{minHeight: '100vh', display: 'flex', alignItems: 'center'}}
    >
      <div className="container" style={{maxWidth: 800}}>
        <div className="row">
          <div className="col-12 col-lg-2"></div>
          <div className="col-12">
            {page < 4 && (
              <>
                <div>SCHRITT {page} / 3</div>
                <h2 className="my-1">{pages[page - 1].title}</h2>
              </>)}
            {pages[page - 1].item}
            {!isMobile && page < 4 &&
              <div className="hstack" style={{position: 'absolute', right: '1rem', bottom: '4%'}}>
                <div></div>
                <Button onClick={scrollBack} className={`${page < 4 ? 'text-primary' : 'text-c1'} hstack gap-3`}>
                  <div style={{transform: 'rotate(180deg)'}}><IconArrow/></div>
                  <div>zurück zum Start</div>
                </Button>
              </div>
            }
          </div>
          <div className="col-12 col-lg-2"></div>
        </div>
      </div>
    </section>
  );
}