import ImpressumArticle from './impressum';
import DatenschutzArticle from './datenschutz';
import { isMobile } from '../../helper/constants';

export default function Article({article}) {

  const articles = {
    impressum: <ImpressumArticle/>,
    datenschutz: <DatenschutzArticle/>,
  };
  return <div className={'article-page'}>
    {!isMobile && <div className="header-helper"></div>}
    {articles[article || 'impressum']}
  </div>;
}