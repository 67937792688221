import { motion } from 'framer-motion';

export default function Headline() {

  return (
    <motion.div
      initial={{opacity: 0, scale: 0.5}}
      animate={{opacity: 1, scale: 1}}
      transition={{duration: 0.5}}
      style={{fontSize: '5.3em'}}
    >
      Spielplatz<br/>
      Management<br/>
      System
    </motion.div>
  );
}