import { motion } from 'framer-motion';

export default function Button(props) {

  const thisProps = {
    ...props,
    className: props.className || 'hover link',
  };

  return (
    <motion.div whileHover={{scale: 1.1}} {...thisProps} >
      {props.children}
    </motion.div>
  );
}