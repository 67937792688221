import { isMobile } from '../../helper/constants';

export default function IconArrow() {

  return isMobile ?
    <svg width="30" height="34" viewBox="0 0 30 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 2C17 0.895431 16.1046 3.27186e-08 15 0C13.8954 -3.27186e-08 13 0.89543 13 2L17 2ZM13.5858 33.4142C14.3668 34.1953 15.6332 34.1953 16.4142 33.4142L29.1421 20.6863C29.9232 19.9052 29.9232 18.6389 29.1421 17.8579C28.3611 17.0768 27.0948 17.0768 26.3137 17.8579L15 29.1716L3.68629 17.8579C2.90524 17.0768 1.63891 17.0768 0.857863 17.8579C0.0768145 18.6389 0.0768145 19.9052 0.857863 20.6863L13.5858 33.4142ZM13 2L13 32L17 32L17 2L13 2Z"
        fill="currentColor"
      />
    </svg>
    : <div style={{fontSize: '1rem'}}>
      <svg width="44" height="30" viewBox="0 0 44 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2 13C0.895431 13 9.65645e-08 13.8954 0 15C-9.65645e-08 16.1046 0.89543 17 2 17L2 13ZM43.4142 16.4142C44.1953 15.6332 44.1953 14.3668 43.4142 13.5858L30.6863 0.857867C29.9052 0.076818 28.6389 0.0768179 27.8579 0.857867C27.0768 1.63892 27.0768 2.90525 27.8579 3.68629L39.1716 15L27.8579 26.3137C27.0768 27.0948 27.0768 28.3611 27.8579 29.1421C28.6389 29.9232 29.9052 29.9232 30.6863 29.1421L43.4142 16.4142ZM2 17L42 17L42 13L2 13L2 17Z"
          fill="currentColor"/>
      </svg>
    </div>

    ;
}