import { motion } from 'framer-motion';
import IconArrow from '../Icons/arrow';
import Button from '../Button';

export default function FooterButton({scrollXProgress, contactFormPosition}) {
  return (
    <footer style={{display: contactFormPosition > 0.7 ? 'none' : 'flex'}}>
      <motion.div style={{
        x: scrollXProgress * 55 + 'vw',
        opacity: -contactFormPosition * 10,
      }}>
        <div className="pill bg-y1 p-3" style={{width: 900, marginLeft: 'auto'}}>
          <div className="hstack">
            <div className="ms-3">
              Helfen Sie uns, Ihre Bedürfnisse in drei einfachen Schritten besser zu verstehen.
            </div>
            <div style={{marginLeft: 35, marginRight: 42}}><IconArrow/></div>
            <a href={'#contact-form'}>
              <Button className="me-3" style={{width: '350px', fontSize: 64, flexShrink: 0}}>
                Los geht's
              </Button>
            </a>
          </div>
        </div>
      </motion.div>
    </footer>
  );
}