import { useRouteError } from 'react-router-dom';

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  document.body.setAttribute('class', 'page-error');


  window.setTimeout(() => {

      window.location = '/';
    }, 1000,
  );


  return (
    <div
      id="error-page"
      style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <div>
        <h1>Oops!</h1>
        <p>Sorry, hier gibt es nichts zu sehen.</p>
        <p>Es geht gleich weiter...</p>
        <p className="opacity-25 small">
          <i>{error.statusText || error.message}</i>
        </p>
      </div>
    </div>
  );
}