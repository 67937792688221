import React, { useState } from 'react';
import Button from '../Button';
import { isMobile, needs } from '../../helper/constants';


export default function ContactFormPage1({setPage, setNeed}) {
  const [activeNeeds, setActiveNeeds] = useState([]);


  const onHashClick = (index, tagIndex) => {
    const need = index + '-' + tagIndex;
    // const newNeed = activeNeeds.includes(need) ? activeNeeds.filter(i => i !== need) : [...activeNeeds, need];
    // setActiveNeeds(newNeed);
    setActiveNeeds([need]);
    setNeed(need);
  };

  return (
    <>
      <div style={{fontSize: 24}} className="mb-3">
        Teilen Sie uns in drei einfachen Schritten mit, was Sie benötigen.
      </div>
      {isMobile ? <div>
          {needs.map((btnText, index) => (
            <div
              key={index}
              style={{padding: 16}}
              onClick={() => {
                setNeed(index + '-0');
                setPage(2);
              }}
              className={`${activeNeeds.find(i => i.startsWith(index + '-')) ? 'text-primary' : 'text-w1'} btn-outline mb-4`}
            >
              {btnText.label}
            </div>
          ))}
        </div>
        :
        <div className={'grid'}>
          {needs.map((btnText, index) => (
            <React.Fragment key={index}>
              <div
                style={{gridRow: index + 1, gridColumn: 1, alignSelf: 'center'}}
                className={`${activeNeeds.find(i => i.startsWith(index + '-')) ? 'text-primary' : 'text-w1'} `}
                onClick={() => onHashClick(index, 0)}
              >
                {btnText.label}
              </div>
              <div
                style={{
                  gridRow: index + 1,
                  gridColumn: 2,
                  alignSelf: 'center',
                  display: 'inline-flex',
                  whiteSpace: 'pre-wrap',
                  flexWrap: 'wrap',
                }}
              >
                {btnText.hashtags.map((tag, tagIndex) => (
                  <div
                    key={tagIndex}
                    className={`${activeNeeds.includes(index + '-' + tagIndex) ? 'text-primary' : 'text-w1'} me-1 mb-2 hover`}
                    style={{fontWeight: 300, fontSize: 18, padding: '0 10px'}}
                    onClick={() => onHashClick(index, tagIndex)}
                  >
                    {tag}
                  </div>
                ))}
              </div>
            </React.Fragment>
          ))}
        </div>
      }

      <div className="hstack mt-3" style={{minHeight: 50}}>
        <div></div>
        {activeNeeds.length > 0 &&
          <a href={'#cf-next-2'} className="ms-auto ">
            <Button className="btn text-y1 block" onClick={() => setPage(2)}>Weiter</Button>
          </a>
        }
      </div>
    </>
  );

}