export default function MainPageSection2(props) {

  return (
    <section {...props}>
      <div style={{marginBottom: 64}}>
        "Unser digitales System unterstützt Sie spielend einfach bei den Pflichtaufgaben und hilft Ihnen kluge
        Entscheidung zu treffen."
      </div>
      <div className="fw-light">
        Keine Unsicherheiten und kein Gesuche mehr!
      </div>
    </section>
  );
}