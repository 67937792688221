export default function MainPageSection4(props) {

  return (
    <section {...props}>
      <div style={{fontSize: '2em'}}>
        "Wir machen es wirklich einfach und zeigen, dass der Spielplatz alle Regeln befolgt."
      </div>
    </section>
  );

}