import * as React from 'react';
import * as ReactDOM from 'react-dom/client';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Root from './routes/root';
import './style/main.scss';
import ErrorPage from './components/ErrorPage';
import 'bootstrap';

export const paths = [
  {path: '/', label: 'Home', themeColor: '#2E305D'},
  {path: '/wartung', label: 'Wartung', themeColor: '#FFD600'},
  {path: '/inspektion', label: 'Inspektion', themeColor: '#8FFF00'},
  {path: '/cloud', label: 'Cloud', themeColor: '#2E305D'},
  {path: '/impressum', label: 'Impressum', hidden: true, themeColor: '#FFD600'},
  {path: '/datenschutz', label: 'Datenschutz', hidden: true, themeColor: '#FFD600'},
  // {path: '/team', label: 'Team'},
  {path: '#contact-form', label: 'Kontakt'}];

const routes = paths.map(path => ({
  path: path.path,
  element: <Root currentPath={path.path}/>,
  errorElement: <ErrorPage/>,
}));
const router = createBrowserRouter(routes);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>,
);