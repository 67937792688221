import { isMobile } from '../../helper/constants';
import IconLogo from '../Icons/logo';
import { motion } from 'framer-motion';
import { goToStart } from '../../helper/navigation';

export default function HeaderLogo({darkTheme}) {

  return (<motion.div whileHover={{scale: 1.1}}
    // className={darkTheme ? 'text-primary' : 'text-b1'}
                      className={darkTheme ? 'text-primary' : ''}
                      style={{width: isMobile ? 100 : 140, transition: 'color 1s'}}
                      onClick={() => {
                        goToStart();
                      }}
  >
    <a href={'/'}><IconLogo/></a>
  </motion.div>);
}
