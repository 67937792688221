import { isMobile } from '../../helper/constants';
import ScrollForMore from '../ScrollForMore';

export default function MainPageSection1(props) {

  return (
    <section {...props}>
      <div style={{fontSize: '4.5em', marginBottom: isMobile ? 40 : 60}}>
        Verhindert Probleme,<br/>
        bevor sie auftreten!
      </div>
      <ScrollForMore/>
    </section>
  );

}