const baseUrl = 'https://app.wadiki.cloud/api/';

export const postApi = async (url, data) => {

  return await fetch(baseUrl + url, {
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Accept': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(data),
  }).then(async response => {
    const isError = !response.ok;
    const data = await response.json();
    return {...data, isError};
  });
};


export const newLead = async (formData) => {
  return await postApi('leads', formData);
};
