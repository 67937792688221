import srcCV1 from '../../assets/img/christian-veit-01.png';
import srcCV2 from '../../assets/img/christian-veit-02.png';
import srcSJ1 from '../../assets/img/susanne-juhrich-01.png';
import srcPhoneYellow from '../../assets/img/handy-mock-up-yellow.png';
import srcPhoneBlue from '../../assets/img/handy-mock-up.png';
import { isMobile } from '../../helper/constants';

export default function Hero({width, variant, pillColor, small}) {

  const src = {
    CV1: {src: srcCV1, alt: 'Wadiki Cloud - Christian Veit'},
    CV2: {src: srcCV2, alt: 'Wadiki Cloud - Christian Veit'},
    SJ1: {src: srcSJ1, alt: 'Wadiki Cloud - Susanne Juhrich'},
    phoneYellow: {src: srcPhoneYellow, alt: 'Wadiki Cloud - Handy MockUp'},
    phoneBlue: {src: srcPhoneBlue, alt: 'Wadiki Cloud - Handy MockUp'},
  };

  const styles = {
    maxWidth: width || 'auto',
    zIndex: 2,
    position: 'relative',
    maxHeight: small ? 500 : 'calc(100vh - 120px)',
    margin: '0 auto',
    marginTop: 'auto',
  };

  return (
    <div style={{
      position: 'relative',
      width: '100%',
      display: 'flex',
      alignItems: 'end',
      zIndex: 500,
      height: (isMobile || small) ? 'unset' : '100vh',
    }}
         className="px-5">
      <img src={src[variant || 'CV1'].src} style={styles} alt={src[variant || 'CV1'].alt}/>
      {pillColor && <div className={`${pillColor} big-pill ms-40`}></div>}
    </div>
  );
}