export default function MainPageSection2(props) {

  return (
    <section {...props}>
      <div style={{fontSize: '1.7em'}}>
        "Meine Geschichte zeigt, wie Herausforderungen zu innovativen Lösungen führen können, die der Gemeinschaft
        zugutekommen."
      </div>
      <div className="mt-5" style={{fontSize: '1.5em'}}>
        Christian Veit - WADIKI
      </div>
    </section>
  );
}