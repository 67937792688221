import useMousePosition from '../../helper/useMousePosition';
import { motion } from 'framer-motion';
import pointerImg from '../../assets/img/pointer.png';
import pointerLightImg from '../../assets/img/pointer-light.png';

const transparentPixel = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
const defaultCursor = 'url("' + transparentPixel + '"), auto';

export default function CustomCursor({darkTheme}) {

  const mousePosition = useMousePosition();

  const mouseShadowColor = darkTheme ? 'rgba(0,0,0,0.4)' : 'rgba(0,0,0,0.1)';
  const positionFactor = -25;
  const offsetX = (mousePosition.xr - 0.5) * positionFactor;
  const offsetY = (mousePosition.yr - 0.5) * positionFactor;
  const mouseShadowStyle = offsetX + 'px ' + offsetY + 'px 3px ' + mouseShadowColor;

  return (
    <motion.div
      style={{x: mousePosition.x, y: mousePosition.y, zIndex: 1060}}
    >
      <div
        style={{
          width: 26,
          height: 26,
          position: 'fixed',
          top: -13,
          left: -13,
          pointerEvents: 'none',
          borderRadius: 13,
          boxShadow: mouseShadowStyle,
        }}
      >
        {mousePosition.type === defaultCursor ?
          <img src={darkTheme ? pointerLightImg : pointerImg} alt={''}/> :
          <motion.img
            animate={{scale: [0.9, 1.1]}}
            transition={{
              duration: 0.5, repeat: Infinity,
            }}
            src={pointerLightImg}/>
        }
      </div>
    </motion.div>
  );
}