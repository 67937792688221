import MainPageSection1 from './section1';
import MainPageSection2 from './section2';
import ContactForm from '../ContactForm';
import ContactFormHeader from '../ContactForm/header';
import Hero from '../Heros';
import { textBlock } from '../../helper/constants';


export default function MobilePageContentInspektion({x}) {
  return (
    <>
      <div className="p-40" style={{fontSize: '10px'}}>
        <MainPageSection1/>
      </div>
      <div className="p-40 pb-0" style={{fontSize: '2em'}}>
        <h1>WADIKI Inspektion</h1>
        <div className="p-2"></div>
        <MainPageSection2/>
        <div className="p-5"></div>
        <div>
          "{textBlock.inspection[0]}"
        </div>
        <div className="p-4"></div>
        <div>
          Susanne
        </div>
        <div className="p-3"></div>
      </div>
      <Hero variant={'SJ1'} width={'70vw'} pillColor={'lg-y-g'}/>

      <div>

      </div>

      <ContactFormHeader page={'inspection'}/>
      <ContactForm/>
    </>
  );
}