import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import Button from '../Button';
import { needs } from '../../helper/constants';

export default function ContactFormPage2({setFormData, need, setPage, formData}) {
  const form = useRef();
  const formSubmitButton = useRef();
  const needIndex = need.split('-')[0];
  const hashIndex = need.split('-')[1];
  const activeNeed = needs[needIndex];
  const activeHash = activeNeed.hashtags[hashIndex];

  const {register, handleSubmit} = useForm({
    shouldUseNativeValidation: true,
  });

  const onSubmit = async (data) => {
    setFormData(data);
    setPage(3);
  };

  return (
    <>
      <div style={{fontSize: 24}} className="mb-3">
        Offenbar benötigen Sie Hilfe bei der <span className="text-primary">{activeNeed.label}</span>.
      </div>
      <form onSubmit={handleSubmit(onSubmit)} ref={form}>
        <div className={'grid'}>
          <input
            {...register('need')}
            value={activeNeed.label}
            hidden
          />
          <input
            {...register('hash')}
            value={activeHash}
            hidden
          />
          <input
            {...register('address')}
            defaultValue={formData?.address || ''}
            placeholder="Adresse des Spielplatzes"
            style={{gridColumn: '1 / span 2', display: 'grid'}}
          />
          <input
            {...register('zip')}
            defaultValue={formData?.zip || ''}
            placeholder="PLZ"
          />
          <input
            {...register('city')}
            defaultValue={formData?.city || ''}
            placeholder="Stadt"
          />

          <textarea
            {...register('description')}
            defaultValue={formData?.description || ''}
            placeholder="Erzählen Sie uns hier mehr darüber"
            style={{gridColumn: '1 / span 2', display: 'grid'}}
          />
          <div className="d-none">
            <div style={{gridColumn: '1 / span 2'}} className="d-flex gap-3 flex-column flex-lg-row">
              <input type="file" name={'images'} className={'d-none'}/>
              <input type="file" name={'documents'} className={'d-none'}/>
              <div className="btn text-y1">Bilder hochladen</div>
              <div className="btn text-y1">Dokumente hochladen</div>
            </div>
          </div>
          <input
            {...register('name')}
            defaultValue={formData?.name || ''}
            placeholder="Name"
          />
          <input
            {...register('email')}
            defaultValue={formData?.email || ''}
            placeholder="E-Mail"
          />
          <div></div>
        </div>
        <div className="d-none">
          <input type={'submit'} ref={formSubmitButton}/>
        </div>
        <div className="d-flex flex-column flex-md-row gap-3">
          <a href={'#cf-prev-2'} className="me-auto ">
            <Button className="btn-outline block text-w1" onClick={() => setPage(1)}>Zurück</Button>
          </a>
          <a href={'#cf-next-3'} className="ms-auto ">
            <Button onClick={() => formSubmitButton.current.click()} className="btn block text-y1">weiter</Button>
          </a>
        </div>
      </form>

    </>
  );

}