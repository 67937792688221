import Hero from '../Heros';
import IconArrow from '../Icons/arrow';
import { textBlock } from '../../helper/constants';
import { motion } from 'framer-motion';

export default function BlackPill({page, x}) {

  const text = {
    wartung: {
      header: 'Es ist einfach',
      firstSection: textBlock.wartung[0],
      secondHeader: 'Wartung anfragen',
      hero: 'CV2',
    },
    inspektion: {
      header: 'Es spart Zeit',
      firstSection: textBlock.inspection[0],
      secondHeader: 'Inspektion anfragen',
      hero: 'SJ1',
    },
    cloud: {
      header: 'Es reduziert Kosten',
      firstSection: textBlock.cloud[0],
      secondHeader: 'Jetzt berechnen',
      hero: 'CV1',
    },
  };

  return <div style={{position: 'relative'}} className="black-stripe-wrapper">
    <div>{text[page].header}</div>
    <div className={'pill black-stripe'} style={{position: 'relative'}}>
      <section style={{width: 710}}>
        <div style={{fontSize: 36}}>{text[page].firstSection}</div>
      </section>
      <motion.div style={{
        height: 300,
        x: x * 600 - 300,
        position: 'relative',
        top: -200,
      }}>
        <Hero variant={text[page].hero} width={'16.6rem'} small={true}/>
      </motion.div>
      <section>
        <div className="text-primary" style={{fontSize: 64, marginBottom: 30}}>
          {text[page].secondHeader}
        </div>
        <div className="hstack gap-3">
          <div style={{fontSize: 24}}>
            Erhalten Sie in drei einfachen<br/>Schritten Ihr kostenloses Angebot.
          </div>
          <div className="text-primary">
            <IconArrow/>
          </div>
        </div>
      </section>
    </div>
    <div style={{position: 'absolute', top: -3000, right: 0, width: 400, bottom: -3000}} className={'bg-c1'}></div>
  </div>;
}