import { useMotionValueEvent, useScroll } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import PageContentHome from '../PageContentHome';
import PageContentWartung from '../PageContentWartung';
import PageContentInspektion from '../PageContentInspektion';
import PageContentCloud from '../PageContentCloud';
import Footer from '../Footer';
import Article from '../PageArticles';


export default function Container({onScroll, page, setContactFormPosition}) {
  const [x, setX] = useState(0);
  const scrollRef = useRef();
  const {scrollXProgress, scrollX} = useScroll({container: scrollRef});

  useMotionValueEvent(scrollXProgress, 'change', (latest) => {
    onScroll && onScroll(latest);
    setX(latest);
  });

  useMotionValueEvent(scrollX, 'change', (latest) => {
    const contactForm = document.getElementById('contact-form');
    const width = contactForm.offsetWidth;
    const left = contactForm.offsetLeft;
    const prog = (latest - left + width) / width;
    setContactFormPosition && setContactFormPosition(prog);
  });

  useEffect(() => {
    window.removeEventListener('wheel', onWheel);
    window.addEventListener('wheel', onWheel);
  }, []);

  const onWheel = (e) => {
    if (-1 <= e.deltaY && e.deltaY <= 1) return;
    const item = scrollRef.current;
    item.scrollLeft += (e.deltaY + e.deltaX) > 0 ? window.innerWidth * 0.8 : window.innerWidth * -0.8;
    // window.innerWidth * 0.8;
    // window.innerWidth * 0.8;
    // ele.scrollBy(e.deltaY * 5, 0);
    // item.scrollBy({
    //   left: e.deltaY,
    //   // behavior: 'smooth',
    // });
  };

  return (
    <div id={'float-container'} className={'float-container page-' + page.substring(1)} ref={scrollRef}>
      {page === '/' && <PageContentHome x={x}/>}
      {page === '/wartung' && <PageContentWartung x={x}/>}
      {page === '/inspektion' && <PageContentInspektion x={x}/>}
      {page === '/cloud' && <PageContentCloud x={x}/>}
      {page === '/impressum' && <Article article={'impressum'}/>}
      {page === '/datenschutz' && <Article article={'datenschutz'}/>}
      <Footer/>
    </div>
  );
}