export const breakPoint = 500;
export const isPortrait = window.innerHeight > window.innerWidth;
export const isMobile = window.screen.width < breakPoint;
export const url = window.location.href.toString().split(window.location.host)[1];
export const hashTag = window.location.hash;
export const page = url.replace(hashTag, '').split('?')[0];


const notSureNeed = {
  label: 'Sonstiges',
  hashtags: ['#nicht sicher', '#Beratung', '#alles'],
};

const digiNeed = {
  label: 'Digitalisierung',
  hashtags: ['#Spielplatz Verwaltung', '#Mängelmelder', '#Wadiki Cloud Konzept'],
};

const wartungNeeds = [
  {
    label: 'Wartung',
    hashtags: ['Wir überprüfen Ihren Spielplatz auf potentielle Gefahrenquellen und reduzieren Ihr Haftungsrisiko'],
  }, {
    label: 'Reparatur',
    hashtags: ['Wir reparieren Ihre Spielplatzgeräte unter Einhaltung der Norm DIN EN 1176 und liefern Original-Ersatzteile'],
  }, {
    label: 'Wartungskonzept',
    hashtags: ['Wir erarbeiten Ihnen ein individuelles Wartungskonzept und erhöhen die Lebenszeit Ihrer Spielplatzgeräte'],
  },
  digiNeed,
  notSureNeed,
];

const inspektionNeeds = [
  {
    label: 'Erstabnahme vor Inbetriebnahme',
    hashtags: ['Wir übernehmen die sachkundige Erstabnahme bei Installationen neuer Spielplatzgeräte'],
  }, {
    label: 'Operative Inspektion',
    hashtags: ['je nach Angaben des Herstellers  und Frequenz der Nutzer hat aller 1 - 3 Monate eine detaillierte Inspektion mit besonderen Augenmerk auf Verschleiß zu erfolgen'],
  }, {
    label: 'Jährliche Hauptinspektion',
    hashtags: ['Die Hauptinspektion ist die umfangreichste Spielplatzprüfung und ist einmal im Jahr durchzuführen'],
  },
  digiNeed,
  notSureNeed,
];


export const mainNeeds = [
  {
    label: 'Montage',
    hashtags: ['#Neubau', '#Umbau', '#Demontage'],
  },
  {
    label: 'Inspektion',
    hashtags: ['#Jährliche Hauptinspektion', '#Operative Inspektion', '#Visuelle Inspektion'],
  },
  {
    label: 'Wartung',
    hashtags: ['#Reparatur', '#Wartung'],
  },
  digiNeed,
  notSureNeed,
];


export const needs = {
  '/': mainNeeds,
  '/wartung': wartungNeeds,
  '/inspektion': inspektionNeeds,
  '/cloud': mainNeeds,
}[page];


export const textBlock = {
  wartung: [
    'Das Wadiki Cloud Konzept sorgt für höchste Sicherheit und jede Menge Spaß auf Kinderspielplätzen, indem es übliche Probleme gar nicht erst entstehen lässt!',
    'Wir kümmern uns um Spielplätze und reparieren diese gemäß DIN EN 1176, um eine dauerhaft sichere Umgebung zu gewährleisten und allen Nutzern Freude zu bereiten.',
    'Wie ein Superheld, der Probleme bereits im Keim erstickt, bevor sie überhaupt die Chance haben, richtig groß zu werden.',
  ],
  inspection: [
    'Die Prüfungen werden digital nach den Richtlinien der DIN EN 1176 durchgeführt und gewährleisten so eine gründliche und transparente Dokumentation.',
  ],
  cloud: [
    'Wir bieten ein ganzheitliches System für eine professionelle, digitale und ressourcenschonende Verwaltung.',
  ],
};