import ButtonMenu from '../components/ButtonMenu';
import MobileContainer from '../components/Container/mobile';
import HeaderLogo from '../components/Header/logo';
import { paths } from '../index';

export default function MobileWrapper({currentPath}) {

  const darkTheme = ['/cloud'].includes(currentPath);

  const thisPath = paths.find(i => i.path === currentPath);

  const color = document.getElementById('theme-color');
  color.content = thisPath.themeColor;

  return (
    <div className="mobile-wrapper">
      <div id="mobile-wrapper"></div>
      <div className="d-flex justify-content-between p-40" id="Start">
        <HeaderLogo darkTheme={darkTheme}/>
        <ButtonMenu currentPath={currentPath} darkTheme={darkTheme}/>
      </div>
      <MobileContainer page={currentPath}/>
    </div>
  );
}