import React, { useState } from 'react';
import Button from '../Button';
import { newLead } from '../../helper/api';
import Modal from '../Modal';

export default function ContactFormPage3({setPage, formData}) {
  const [loading, isLoading] = useState(false);
  const [error, setError] = useState(false);

  const sendData = async () => {
    // setPage(4);
    // return;
    isLoading(true);
    setError(false);
    formData['problem'] = formData.need + '#' + formData.hash;
    const response = await newLead(formData);
    if (!response.isError) {
      setPage(4);
    } else {
      setError(response);
    }
    isLoading(false);
  };

  return (
    <>
      <div style={{fontSize: 24}} className="mb-3">
        Bitte Überprüfen Sie noch einmal alle Eingaben
      </div>
      <div className="text-primary mb-3" style={{fontSize: 24}}>
        <div>{formData.address}</div>
        <div>{formData.zip} {formData.city}</div>
        <div className="mt-2">{formData.need} #{formData.hash}</div>
        <div>{formData.description}</div>
        <div className="mt-2">{formData.name}</div>
        <div>{formData.email}</div>
      </div>

      <div className="d-flex flex-column flex-md-row gap-3 justify-content-between">
        <a href={'#cf-prev-3'}>
          <Button className="btn-outline block text-w1" onClick={() => setPage(2)}>Zurück</Button>
        </a>
        {!loading && !error &&
          <a href={'#cf-send'}>
            <Button className="btn block text-primary" onClick={sendData}>Absenden</Button>
          </a>
        }
        {loading && <div className="text-primary">Wird gesendet...</div>}
      </div>
      {error &&
        <Modal
          show={true}
          onClose={() => {
            setError(false);
          }}
          footer={<div></div>}
          content={(
            <div id="api-error-response">
              Es ist ein Fehler aufgetreten.
              {error.violations ? error.violations.map((msg, index) => {
                return (
                  <div key={index} className="small mt-3">
                    {msg.propertyPath === 'name' && <div className="text-primary">Name</div>}
                    {msg.propertyPath === 'email' && <div className="text-primary">E-Mail {formData.email}</div>}
                    <div className="fw-light badge badge-danger">{msg.message}</div>
                  </div>
                );
              }) : <div>Bitte versuchen Sie es später wieder.</div>
              }
            </div>
          )}
        />}
    </>
  );

}