import Button from '../Button';
import { useState } from 'react';
import { paths } from '../../index';
import { isMobile } from '../../helper/constants';
import IconClose from '../Icons/close';

export default function ButtonMenu({currentPath, darkTheme}) {
  const [show, setShow] = useState(false);

  return (
    <div>
      <a href={'#menu-open'}>
        <Button
          className={`${darkTheme ? 'text-w1' : 'text-b1'} ${isMobile ? 'hover fs-3' : 'btn-outline'} `}
          onClick={() => setShow(true)}
        >
          Menü
        </Button>
      </a>
      {show === true && <div
        id="gt-menu-wrapper"
        className="bg-c1 text-w1"
        style={{
          display: 'flex',
          alignItems: 'center',
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          fontSize: '2em',
          justifyContent: 'center',
          opacity: 0.99,
          zIndex: 1000,
        }}>
        <div>

          <div style={{
            position: isMobile ? 'relative' : 'absolute',
            right: isMobile ? 'calc(-50% + 30px )' : 40,
            top: isMobile ? -20 : 30,
          }}>
            <a href={'#menu-close'}>
              <Button
                onClick={() => {
                  setShow(false);
                }}
              >
                <IconClose/>
              </Button>
            </a>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-2"></div>
              <div className="col-12 col-lg-2"></div>
            </div>
            <div className="row">
              <div className="col-12 col-md-2"></div>
              <div className="col text-center">
                {paths.map(path => {
                  if (path.hidden) return null;

                  return <a href={path.path} key={path.path}>
                    <Button
                      onClick={() => {
                        setShow(false);
                      }}
                      className={`${path.path === currentPath ? 'text-y1' : 'text-w1'} mb-3 link`}
                    >
                      {path.label}
                    </Button>
                  </a>;
                })}
              </div>
              <div className="col-12 col-md-2"></div>
            </div>
          </div>
        </div>
      </div>}
    </div>
  );
}