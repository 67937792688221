import MainPageSection2 from './section2';
import MainPageSection3 from './section3';
import MainPageSection4 from './section4';
import MainPageSection5 from './section5';
import MainPageSection6 from './section6';
import CloudPageSection2 from '../PageContentCloud/section2';
import Hero from '../Heros';
import Headline from '../Headline';
import ContactForm from '../ContactForm';
import ContactFormHeader from '../ContactForm/header';
import IconArrow from '../Icons/arrow';

export default function MobilePageContentHome() {
  return (
    <>
      <div className={'bg-w1 p-40 text-end'} style={{fontSize: 9}}>
        <Headline/>
      </div>

      <div
        style={{
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
          marginTop: -30,
        }}
      >
        <Hero variant={'CV1'} width={'60vw'} pillColor={'lg-y-g'}/>
        <div style={{position: 'absolute', top: '30%', right: '20%'}}><IconArrow/></div>
      </div>

      <div className={'lg-y-g p-40'}>
        <MainPageSection2/>
      </div>

      <div className={'bg-y1 p-40'}>
        <h1 className="mb-5">WADIKI Wartung</h1>
        <MainPageSection3 className="mb-5"/>
        <MainPageSection4 style={{fontSize: '0.8rem', fontWeight: 300}}/>
      </div>

      <div className={'bg-g1 p-40'}>
        <h1 className="mb-5">WADIKI Inspektion</h1>
        <MainPageSection5 className="mb-5"/>
        <MainPageSection6 style={{fontSize: '0.8rem', fontWeight: 300}}/>
      </div>

      <div className={'bg-b1 p-40 pb-0 text-w1'} style={{paddingBottom: 0}}>
        <h1 className="mb-5">WADIKI Cloud</h1>
        <CloudPageSection2 style={{fontSize: 32, fontWeight: 600}}/>
        <div className="p-5"></div>
        <Hero variant={'phoneYellow'} width={'60vw'}/>
      </div>
      <ContactFormHeader page={'home'}/>
      <ContactForm/>
    </>
  );
}