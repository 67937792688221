import { useEffect, useState } from 'react';


// {content, title, footer, show, onClose}

export default function Modal(props) {
  const [open, isOpen] = useState(props.show);

  useEffect(() => {
    isOpen(props.show);
  }, [props.show]);


  const onClose = () => {
    isOpen(false);
    props.onClose && props.onClose();
  };

  return (
    <div
      style={{
        position: 'fixed',
        inset: 0,
        zIndex: 2000,
        display: open ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0,0,0,0.8)',
        fontSize: 24,
      }}
      className="" tabIndex="-1" role="dialog">
      <div
        style={{maxWidth: 400, width: '90%'}}
        className="rounded bg-b1 text-w1 shadow overflow-hidden"
        role="document"
      >
        <div className="modal-content overflow-hidden">
          <div className="d-flex p-3 bg-g1 text-b1">
            <h5 className="modal-title">{props.title || 'Fehler'}</h5>
            <div className="ms-auto hover" onClick={onClose}>
              <span aria-hidden="true">&times;</span>
            </div>
          </div>
          <div className="modal-body p-3 my-4">
            {props.content}
          </div>
          <div className="modal-footer p-3 bg-g1">
            {props.footer || (
              <>
                <button type="button" className="btn btn-primary">Save changes</button>
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              </>
            )}
            <div className="d-flex justify-content-end">
              <div className="text-b1 btn-outline" onClick={onClose}>Okay</div>
            </div>
          </div>
        </div>
      </div>
    </div>);
}