import MobilePageContentHome from '../PageContentHome/mobile';
import MobilePageContentWartung from '../PageContentWartung/mobile';
import MobilePageContentInspektion from '../PageContentInspektion/mobile';
import MobilePageContentCloud from '../PageContentCloud/mobile';
import Footer from '../Footer';
import Article from '../PageArticles';


export default function MobileContainer({page}) {

  return (
    <div className={'mobile-page page-' + page.substring(1)}>
      {page === '/' && <MobilePageContentHome/>}
      {page === '/wartung' && <MobilePageContentWartung/>}
      {page === '/inspektion' && <MobilePageContentInspektion/>}
      {page === '/cloud' && <MobilePageContentCloud/>}
      {page === '/impressum' && <Article article={'impressum'}/>}
      {page === '/datenschutz' && <Article article={'datenschutz'}/>}
      <Footer/>
    </div>
  );
}