import React from 'react';

const useMousePosition = () => {
  const [
    mousePosition,
    setMousePosition,
  ] = React.useState({x: null, y: null, xr: null, yr: null, type: null});

  React.useEffect(() => {
    const updateMousePosition = ev => {
      setMousePosition({
        x: ev.clientX,
        y: ev.clientY,
        xr: ev.clientX / window.innerWidth,
        yr: ev.clientY / window.innerHeight,
        type: ev.target.style.cursor || window.getComputedStyle(ev.target)['cursor'],
      });
    };
    window.addEventListener('mousemove', updateMousePosition);
    return () => {
      window.removeEventListener('mousemove', updateMousePosition);
    };
  }, []);
  return mousePosition;
};
export default useMousePosition;