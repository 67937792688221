export default function MainPageSection2(props) {

  return (
    <section {...props}>
      <div style={{marginBottom: 64}}>
        Es ist wirklich toll ein Protokoll zu bekommen, welches man auch versteht!
      </div>
      <div className={'fw-light'}>
        "Wir machen es wirklich einfach und zeigen, dass der Spielplatz alle Regeln befolgt."
      </div>
    </section>
  );
}


